<template>
  <v-container fluid class="text-center">
    <Loader :loading="loading" />
    <Table-Container title="Reporte: Hoja 1" cardTextFooter>
      <template v-slot:buttons>
        <v-col cols="2">
          <v-autocomplete v-model="filters.grupo" :items="catalogues.grupos" item-text="nombre" item-value="idgrupo"
          label="Grupo" class="mx-2" :disabled="loading" required @change="refresh()"></v-autocomplete>
        </v-col>
        <Basic-Btn text="Actualizar" color="success" icon="mdi-refresh" @click="refresh()" :loading="loading" />
      </template>
      <template v-slot:search>
        <Basic-Btn text="Descargar" color="primary" icon="mdi-download" @click="download()" :loading="loading"  />
      </template>
      <v-data-table :headers="headers" :items="items" :loading="loading" :footer-props="{ 'items-per-page-text': 'Elementos por página', 'items-per-page-options': [20, 50, -1] }"
      :items-per-page="20" item-key="id" class="elevation-1" dense>
        <template v-slot:[`item.monto_prestamo`]="{ item }">
          {{ "$ " + formatNumberToMoney(item.monto_prestamo) }}
        </template>
        <template v-slot:[`item.pago_semanal`]="{ item }">
          {{ "$ " + formatNumberToMoney(item.pago_semanal) }}
        </template>
        <template v-slot:[`item.monto_abono`]="{ item }">
          {{ "$ " + formatNumberToMoney(item.monto_abono) }}
        </template>
        <template v-slot:[`item.monto_adeudo`]="{ item }">
          {{ "$ " + formatNumberToMoney(item.monto_adeudo) }}
        </template>
        <template v-slot:[`item.monto_adeudo_atrasado`]="{ item }">
          {{ "$ " + formatNumberToMoney(item.monto_adeudo_atrasado) }}
        </template>
        <template v-slot:[`item.monto_total_entregado`]="{ item }">
          {{ "$ " + formatNumberToMoney(item.monto_total_entregado) }}
        </template>
        
        <v-progress-linear slot="progress" color="secondary" indeterminate></v-progress-linear>
        <template v-slot:no-data> No hay información disponible </template>
        <template v-slot:no-results> No se obtuvieron resultados </template>
      </v-data-table>
    </Table-Container>
    <BasicAlert :active.sync="alert.active" :msg="alert.msg" :color="alert.color" />
  </v-container>
</template>

<script>
import { Loader, DialogForm, BasicAlert, TableContainer, BasicBtn } from "@/components"
import services from "@/utils/services"
import moment from 'moment-timezone'
import downloadjs from 'downloadjs'

export default {
  name: "sheetOne",
  components: {
    Loader,
    DialogForm,
    BasicAlert,
    TableContainer,
    BasicBtn,
  },
  data: () => ({
    alert: {
      active: false,
      color: 'error',
      msg: '',
    },
    loading: false,
    catalogues: {
      grupos: []
    },
    headers: [
      { text: "FECHA DEL PRESTAMO", align: "left", sortable: true, value: "fecha_inicio_prestamo" },
      { text: "ID SOLICITUD", align: "left", sortable: true, value: "id_solicitud" },
      { text: "ID CLIENTE", align: "left", sortable: true, value: "id_cliente" },
      { text: "NOMBRE DEL CLIENTE", align: "left", sortable: true, value: "nombre_cliente" },
      { text: "PRESTAMO", align: "left", sortable: true, value: "monto_prestamo" },
      { text: "FECHA VENCIMIENTO", align: "left", sortable: true, value: "fecha_fin_prestamo" },
      { text: "SEMANA", align: "left", sortable: true, value: "semana" },
      { text: "PAGO SEMANAL", align: "left", sortable: true, value: "pago_semanal" },
      { text: "ABONO", align: "left", sortable: true, value: "monto_abono" },
      { text: "DEUDA POR LIQUIDAR", align: "left", sortable: true, value: "monto_adeudo" },
      { text: "DEUDA ATRASADA", align: "left", sortable: true, value: "monto_adeudo_atrasado" },
      { text: "TOTAL PAGADO", align: "left", sortable: true, value: "monto_total_entregado" },
    ],
    items: [],
    filters: {
      grupo: null
    }
  }),
  mounted() {
    this.getCatalogues()
  },
  methods: {
    formatNumberToMoney(num) {
      try {
        const aux = Number(num);
        return aux
          .toFixed(2)
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      } catch (e) {
        return ''
      }
    },
    async getCatalogues() {
      const group = await this.axios.get(services.routes.group)
      if (group.data.data) {
        this.catalogues.grupos = group.data.data
      }
    },
    refresh() {
      if (this.filters.grupo) {
        this.loading = true
        this.axios.get(services.routes.sheetOneReport + '?grupo=' + this.filters.grupo)
          .then((response) => {
            this.items = response.data.data
          })
          .catch((error) => {
            this.alert.active = true
            this.alert.color = 'error'
            this.alert.msg = error.response.data.message || 'Servicio no disponible'
          })
          .finally(() => {
            this.loading = false
          })
      }
      else {
        this.alert.active = true
        this.alert.color = 'error'
        this.alert.msg = 'Debe seleccionar un grupo disponible'
      }
    },
    download() {
      this.loading = true
      let data = {
        grupo: this.catalogues.grupos.find((elem) => elem.idgrupo === this.filters.grupo)?.nombre,
        fecha: moment.tz('America/Monterrey').format('YYYY-MM-DD HH:mm'),
        headers: this.headers,
        items: this.items
      }
      this.axios({
        url: services.routes.sheetOneReport + '/xlsx',
        method: 'POST',
        data: { data },
        responseType: 'blob'
      })
        .then((response) => {
          downloadjs(response.data, `Reporte Hoja 1 - ${data.grupo}.xlsx`, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
        })
        .catch((error) => {
          this.alert.active = true;
          this.alert.color = "error";
          this.alert.msg = error.response.data.message || "Servicio no disponible";
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
};
</script>

<style scoped>
</style>
